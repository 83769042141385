import Web3 from 'web3'

import { abis } from '../abis/contract'

import tokens from '../tokens'

import { BNB_TEST_PARAMS, BNB_PARAMS, MAIN } from '../helpers/chains'

import { NotificationManager } from 'react-notifications'

//const Web3 = require('web3')

const chains = {
	BNB_TEST_PARAMS: BNB_TEST_PARAMS,
	BNB_PARAMS: BNB_PARAMS,
	MAIN: MAIN
}

const BASE_ZERO = '0x0000000000000000000000000000000000000000'
const MAX = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

let web_provider = null

let web3 = new Web3(new Web3.providers.HttpProvider(chains[process.env.REACT_APP_NETWORK].rpcUrls[0]))

const setWeb3 = (web3Object, active) => {
	web3 = web3Object
	web_provider = active
	return true
}

const initWeb3 = async (settings, setSettings, wallet, setWallet) => {
	const active = settings.provider || 'ethereum'
	if (web_provider) {
		return { WEB3: web3, active }
	}
	if (window[active]) {
		const WEB3 = new Web3(window[active])
		setWeb3(WEB3, active)
		window[active].on('accountsChanged', async e => {
			console.log('accountsChanged')
			if (e.length === 0) {
				setWeb3(null, null)
				setWallet(null)
			}
			window.location.reload()
		})
		window[active].on('chainChanged', async e => {
			console.log('chainChanged')
			const DEFAULT_CHAIN = chains[process.env.REACT_APP_NETWORK]
			if (window[settings.provider].chainId !== DEFAULT_CHAIN.chainId) {
				setWallet(null)
				NotificationManager.error('You are using wrong chain!', 'ERROR', 5000, () => {})
			} else {
				const networkType = await WEB3.eth.net.getNetworkType()
				const networkID = await WEB3.eth.net.getId()
				if (networkID === 0) {
					setWallet(null)
				} else {
					setWallet({ ...wallet, setNetwork: { networkType, networkID } })
				}
				window.location.reload()
			}
		})
		window[active].on('networkChanged', async e => {
			console.log('networkChanged')
			const DEFAULT_CHAIN = chains[process.env.REACT_APP_NETWORK]
			if (window[settings.provider].chainId !== DEFAULT_CHAIN.chainId) {
				setWallet(null)
				NotificationManager.error('You are using wrong chain!', 'ERROR', 5000, () => {})
			} else {
				const networkType = await WEB3.eth.net.getNetworkType()
				const networkID = await WEB3.eth.net.getId()
				if (networkID === 0) {
					setWallet(null)
				} else {
					setWallet({ ...wallet, setNetwork: { networkType, networkID } })
				}
				window.location.reload()
			}
		})
		const accounts = await WEB3.eth.getAccounts()
		const networkType = await WEB3.eth.net.getNetworkType()
		const networkID = await WEB3.eth.net.getId()
		let { selectedAddress } = window[active]
		if (!selectedAddress) {
			selectedAddress = accounts[0]
		}
		let balance = 0
		try {
			const contract = await getContract('erc20', tokens.tokens.OLAND.contractAddress)
			balance = await contract.methods.balanceOf(accounts[0]).call()
			balance = WEB3.utils.fromWei(balance)
		} catch (e) {}
		let diamond = 0
		try {
			const contract = await getContract('erc20', tokens.tokens.DIAMOND.contractAddress)
			diamond = await contract.methods.balanceOf(accounts[0]).call()
			diamond = WEB3.utils.fromWei(diamond)
		} catch (e) {}
		let bust = 0
		try {
			const contract = await getContract('erc20', tokens.tokens.BUST.contractAddress)
			bust = await contract.methods.balanceOf(accounts[0]).call()
			bust = WEB3.utils.fromWei(bust)
		} catch (e) {}
		let packages = []
		try {
			const contract = await getContract('NFTBox', tokens.tokens.NFTBOX.contractAddress)
			const nftIds = _.range(1, 4)
			packages = await contract.methods.balanceOfBatch(Array(nftIds.length).fill(accounts[0]), nftIds).call()
			//console.log(packages)
			packages = WEB3.utils.fromWei(packages)
		} catch (e) {}
		setWallet({ ...wallet, setNetwork: { networkType, networkID }, setAccounts: accounts, setActiveAccount: selectedAddress, balance: balance, diamond: diamond, packages: packages, bust: bust })
		return { WEB3, active }
	}
	console.error('NOT ANY WALLET PLUGIN ISTALLED (Metamask or Celo Wallet)')
	return null
}

const getWeb3 = () => {
	return web3
}

const getWeiBsase = () => {
	if (web3) {
		return web3.utils.toBN('1000000000000000000')
	}
	return null
}

const getContract = async (abiName, contractAddr = null) => {
	if (web3) {
		const abiObject = abis[abiName]
		const contract = contractAddr || abiObject.contract
		if (!contract) {
			throw new Error(`${abiName} Missing contract`)
		}
		return new web3.eth.Contract(abiObject.abi, contract)
	}
	return null
}

const calculatePrice = (price0, price1) => {
	const WEI_BASE = web3.utils.toBN('1000000000000000000')
	let price_diff = price0.div(price1.div(WEI_BASE))
	return {
		str: web3.utils.fromWei(price_diff),
		bn: price_diff
	}
}

const calculatePriceImpact = (price0, price1) => {
	const WEI_BASE = web3.utils.toBN('100000000')
	const res = price0.sub(price1).abs().div(price0.div(WEI_BASE)).mul(web3.utils.toBN(10000)).div(WEI_BASE).toNumber() / 100.0
	return res
}

export { initWeb3, setWeb3, getWeb3, getContract, calculatePrice, calculatePriceImpact, getWeiBsase, BASE_ZERO, MAX }
