const contracts = {
	BNB_TEST_PARAMS: {
		olandAddress: '0x9f9A0a4E867C1B1E51f881074F76D1A2Ceb96ABA',
		woodAddress: '0x549e6b6d152457237358C5e62f1d2c15107498CB',
		metalAddress: '0x0ac180ecE41950B781a01aD2260Ada7877185DC1',
		waterAddress: '0x5cbbEFe37AA9d70b929F332609E6c8ea56F48586',
		foodAddress: '0xDE74A69f8F8b74b8350C3c63236365fA50dF129A',
		rewardAddress: '0xf30AA2D59Aac9e48B56d37387201903c16D08eAc',
		nftAddress: '0x518C21A30e24a7cd76Ebb11Fc364a20Bc80D35c3',
		ankrAddress: '0x4591D13e1cF06279DC155d9b3e39DB0008F27568',
		OceanlandGame: '0x71255aF6182725FF9b5b706F93a452D042888248',
		NFT_Market: '0xA57C98E627A08d9EfbBD8d665Ce291c848b74996',
		DEX: {
			Factory: '0x638bF3a1E4F22Bf33D86aABa03C1358722649B1F',
			Router: '0x400648D19d65453670F75280140C2e5AFd89396E',
			Pairs: {
				OLAND_Water: '0xeCBF20CCA7E2edd691c120f9e3102f48001aD4ac',
				OLAND_Food: '0x9737F55A31C5F4174BaF0e23F21412056bfE3054',
				OLAND_Wood: '0x071634cEcA42AcECF1bd6ADe9C4EeebcC155E027',
				OLAND_Metal: '0x4Ec7fEf70BE88179746Eea82291A7F1beB863645',
				OLAND_ANKR: '0xc1fE537c23c03AFeC6F9B05960D599De8d43916E',
				OLAN_BELLA: '0xF64644e5fe5A73B794e73928f8AE9d02deFa772b',
				OLAND_ARPA: '0xBfB17d39c9e8114e6d0DFF67597d922493Ef3a11'
			}
		},
		diamondToNFT: '0xE3421da5cb926E0DDf91179458aB5d5c3852fE79',
		nftBox: '0xD6064B9d2a420c3eE97758dc28CB02aA1e41B1b4',
		stableUSDT: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
		OlandStakingRewards: '0xb7287BB169f019Fa3c436de58AF11AF818C982F7',
		farmAnkrOland: '0x5C283f61FFD75168c96E58D58C1640a6aFb35664',
		bellaAddres: '0x8443f091997f06a61670B735ED92734F5628692F',
		arpaAddress: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
		olandBellaFarm: '0x38140370D9AFca66e9BE30A990220eB9Ff7EBc47',
		olandArpaFarm: '0x216dd1AA2F011f868fA4D359889Ba1f6c92b62D1'
	},
	BNB_PARAMS: {
		olandAddress: '0xB0461d7E8212D311b842A58e9989edE849ac6816',
		woodAddress: '0x854fDAe212a49d39044312E95a95c3e444062B67',
		metalAddress: '0x7FE51f5Ab455473C2B1F0A7a65c5D83E9671F54A',
		waterAddress: '0x39b9F393187aA9eF7C35FeA762f1E94a3177771d',
		foodAddress: '0xa37DD6dc7E586B7BF366DC285d6ef8F08e8695F0',
		rewardAddress: '0xC30265273521E02507f8D9034d58fFD76DcD1d71',
		nftAddress: '0x24170Cb4e50Efa612D54dc8eF5aa34035360DEB5',
		ankrAddress: '0x4591D13e1cF06279DC155d9b3e39DB0008F27568',
		OceanlandGame: '0x9Cac8ddcEd107b9b880Bcc4CA227Cb8AE6FF13E7',
		NFT_Market: '0x9D02A6772b97e92390aE450341Af732Bb27a96c9',
		DEX: {
			Factory: '0xC97DAe36f230D1B519BE939c404bCbd4eBc26C5d',
			Router: '0xaB479DA6ECdb7b34fC0dA79C7c7EB91C68a2761F',
			Pairs: {
				OLAND_Water: '0xeAA4547385051677725B1299ed272a82359C3a9D',
				OLAND_Food: '0x464E3682DF005ecbBb25FeF7112aF54Be4599381',
				OLAND_Wood: '0xD994e47D711d1884259b48961F1F7b910f248Df8',
				OLAND_Metal: '0xfae83dAf7b2D4b839D9eA599fD5A8Eff8d69D135',
				OLAND_ANKR: '0x76f8Ec8395b527665a2916548E3222eD54E6F23F',
				OLAN_BELLA: '0xF64644e5fe5A73B794e73928f8AE9d02deFa772b',
				OLAND_ARPA: '0xBfB17d39c9e8114e6d0DFF67597d922493Ef3a11'
			}
		},
		diamondToNFT: '0x825F7C1a057DE06FF7508303B9ACCA12651f6960',
		nftBox: '0xdf6d76171e4E4D7361a2f966f1d14b1E311782D9',
		stableUSDT: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
		OlandStakingRewards: '0x2569039175Ed06996Eecaf6a6DA0E817d30BfcF1',
		farmAnkrOland: '0xC49eec7Da8D78Ca3E2f0B63a038e4500f5EF70B9',
		bellaAddres: '0x8443f091997f06a61670B735ED92734F5628692F',
		arpaAddress: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
		olandBellaFarm: '0x38140370D9AFca66e9BE30A990220eB9Ff7EBc47',
		olandArpaFarm: '0x216dd1AA2F011f868fA4D359889Ba1f6c92b62D1'
	},
	MAIN: {
		olandAddress: '0xB0461d7E8212D311b842A58e9989edE849ac6816',
		woodAddress: '0xAeb65dEAC94cf92c9c0392211834cB5213A2Fc13',
		metalAddress: '0xE0c50Bb5EC1E46aBD4c68bE157a122eB98299F06',
		waterAddress: '0x3D222D4342982800ab223E9527FC207B63ABC873',
		foodAddress: '0x087c880b9abbF59b0A134C86620865D3895729C2',
		rewardAddress: '0xc268B9f7FBd53e97F7728Cf30a4e88e20108987F',
		nftAddress: '0x1aB96E3caa981672AC28D77A732F71AAa9248C77',
		ankrAddress: '0x4591D13e1cF06279DC155d9b3e39DB0008F27568',
		OceanlandGame: '0x3d57aE8B565CEA38097c7157f88472Ed60b3AD80',
		NFT_Market: '0x9D02A6772b97e92390aE450341Af732Bb27a96c9',
		DEX: {
			Factory: '0x984Da0774C0d90e054c3707f93863AfFF699B15D',
			Router: '0x2B8f5653475d8528824DC551a7207160Bb789A8f',
			Pairs: {
				OLAND_Water: '0x7241A377Fb9D7b0c70dED62246332018DF0F0Ab0',
				OLAND_Food: '0x46D16F3CbAe48F8fd8D4b77a4a407Bf9e5Ec47F8',
				OLAND_Wood: '0x0a1489B25646dcE35E82E0130ed7e8eD7fbd9330',
				OLAND_Metal: '0xB0EBe7074Bf819a41846df71c9E78A3519232a87',
				OLAND_ANKR: '0x76f8Ec8395b527665a2916548E3222eD54E6F23F',
				OLAN_BELLA: '0xF64644e5fe5A73B794e73928f8AE9d02deFa772b',
				OLAND_ARPA: '0xBfB17d39c9e8114e6d0DFF67597d922493Ef3a11'
			}
		},
		diamondToNFT: '0x825F7C1a057DE06FF7508303B9ACCA12651f6960',
		nftBox: '0xdf6d76171e4E4D7361a2f966f1d14b1E311782D9',
		stableUSDT: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
		OlandStakingRewards: '0x2569039175Ed06996Eecaf6a6DA0E817d30BfcF1',
		farmAnkrOland: '0xC49eec7Da8D78Ca3E2f0B63a038e4500f5EF70B9',
		bellaAddres: '0x8443f091997f06a61670B735ED92734F5628692F',
		arpaAddress: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
		olandBellaFarm: '0x38140370D9AFca66e9BE30A990220eB9Ff7EBc47',
		olandArpaFarm: '0x216dd1AA2F011f868fA4D359889Ba1f6c92b62D1'
	}
}

export { contracts }
