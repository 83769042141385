import React, { useState, Fragment } from 'react'
import { NotificationManager } from 'react-notifications'
import { useRecoilState } from 'recoil'
import { settingsState } from '../atoms/settings'
import { abi, contract } from '../data/abi'
import Preload from './preload'
import { getWeb3, getContract, MAX } from '../helpers/web3'
import tokens from '../tokens'
import { abis } from '../abis/contract'
import _ from 'lodash'
import { contracts } from '../data/contracts'
import LoadingButton from './loading-button'
import { getNFTS, getAskPackage, getNFT, getNFTList } from '../api/nfts'

export default function Modal({ type, nft, text, wallet, setWallet, page }) {
	const [settings, setSettings] = useRecoilState(settingsState)

	const [loading, setLoading] = useState(false)

	const [price, setPrice] = useState(0)

	const [newNFTs, setNewNFTs] = useState([])

	const [buttonLoading, setButtonLoading] = useState(false)

	const formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

	const handleClick = async e => {
		e.preventDefault()

		const WEB3 = getWeb3()
		const coinbase = await WEB3.eth.getCoinbase()
		const walletAddress = coinbase.toLowerCase()
		const oceanlandNFTContractAddress = abis.OceanLandNft.contract
		const oceanlandNFTBoxContractAddress = abis.NFTBox.contract
		const oceanlandMarketAddress = abis.Market.contract
		const oceanlandOLANDAddress = tokens.tokens.OLAND.contractAddress

		const timeout = 5000
		const gas = 500000

		let erc20Contract
		let marketContract
		let allowance
		let approveFunc

		const getNFTPairs = async (nftIds, amounts) => {
			setButtonLoading(true)
			const newList = []
			const response = await getNFTS()
			const nftList = await getNFTList()
			let nfts = []
			if (nftIds.length > 0) {
				_(nftIds).forEach(async (id, index) => {
					if (amounts[index] > 0) {
						for (var i = 0; i < amounts[index]; i++) {
							const nftResponse = _.filter(nftList.data, o => o.nftTokenId === Number(id))
							if (nftResponse.length > 0) {
								newList.push({ ...nftResponse[0], nftTokenId: Number(id) })
							}
						}
					}
				})
				if (response.success) {
					if (response.data) {
						nfts = _.map(newList, item => {
							let filtered = _.filter(response.data, function (o) {
								return o.nftTokenId === item.nftTokenId
							})
							if (filtered.length > 0) {
								filtered = filtered[0]
								return {
									...item,
									id: filtered.id,
									name: _.startCase(filtered.name),
									image: filtered.image,
									owner: filtered.owner,
									ownerLogo: filtered.ownerLogo,
									description: filtered.description,
									category: filtered.category,
									type: filtered.type,
									level: filtered.level,
									reward: filtered.reward,
									coolDown: filtered.coolDown,
									chain: 'bsc'
								}
							}
						})
					}
				}
				setNewNFTs(nfts)
				//setButtonLoading(false)
			}
		}

		const callPackageSale = async quantity => {
			//if (wallet.bust && wallet.bust >= nft.price * nft.quantity) {
			NotificationManager.warning('Processing...', 'WARNING', timeout, () => {})
			const nftBoxContract = await getContract('NFTBox')
			erc20Contract = await getContract('erc20', contracts[process.env.REACT_APP_NETWORK].stableUSDT)
			erc20Contract.methods
				.allowance(walletAddress, nftBoxContract._address)
				.call()
				.then(async allowance => {
					if (parseFloat(WEB3.utils.fromWei(`${WEB3.utils.toWei(`${nft.price * nft.quantity}`)}`)) > parseFloat(WEB3.utils.fromWei(`${allowance}`))) {
						approveFunc = await erc20Contract.methods.approve(nftBoxContract._address, MAX)
						approveFunc
							.send({ from: walletAddress, gas: gas })
							.then(() => {})
							.catch(err => {
								NotificationManager.error(err.message, 'ERROR', timeout, () => {})
								setLoading(false)
							})
					}
				})
				.catch(err => {
					NotificationManager.error(err.message, 'ERROR', timeout, () => {})
					setLoading(false)
				})
			const buyBox = await nftBoxContract.methods.buyBox(nft.id, nft.quantity)
			buyBox
				.send({ from: walletAddress, gas: gas })
				.then(() => {
					setWallet({ ...wallet, nfts: { [`${nft.name}`]: quantity } })
					NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
					setLoading(false)
					window.location.reload()
				})
				.catch(err => {
					NotificationManager.error(err.message, 'ERROR', timeout, () => {})
					setLoading(false)
				})
			//} else {
			//NotificationManager.error('Insufficient Balance!', 'ERROR', timeout, () => {})
			//}
		}

		switch (type) {
			case 'Buy':
				setLoading(true)
				NotificationManager.warning('Processing...', 'WARNING', timeout, () => {})
				marketContract = await getContract('Market')
				erc20Contract = await getContract('erc20', oceanlandOLANDAddress)
				erc20Contract.methods
					.allowance(walletAddress, marketContract._address)
					.call()
					.then(async allowance => {
						if (parseFloat(WEB3.utils.fromWei(`${WEB3.utils.toWei(`${nft.price}`)}`)) > parseFloat(WEB3.utils.fromWei(`${allowance}`))) {
							approveFunc = await erc20Contract.methods.approve(marketContract._address, MAX)
							approveFunc
								.send({ from: walletAddress, gas: gas })
								.then(() => {})
								.catch(err => {
									NotificationManager.error(err.message, 'ERROR', timeout, () => {})
									setLoading(false)
								})
						}
					})
					.catch(err => {
						NotificationManager.error(err.message, 'ERROR', timeout, () => {})
						setLoading(false)
					})
				const buyPackageUsingOLAND = await marketContract.methods.buyPackageUsingOLAND(nft.collectionId === 0 ? oceanlandNFTContractAddress : oceanlandNFTBoxContractAddress, nft.packageId, WEB3.utils.toWei(`${nft.price}`))
				buyPackageUsingOLAND
					.send({ from: walletAddress, gas: gas })
					.then(() => {
						NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
						setLoading(false)
						window.location.reload()
					})
					.catch(err => {
						NotificationManager.error(err.message, 'ERROR', timeout, () => {})
						setLoading(false)
					})
				break
			case 'BuyBooster':
				setLoading(true)
				NotificationManager.warning('Processing...', 'WARNING', timeout, () => {})
				const diamondToNFTContract = await getContract('DiamondToNFT')
				erc20Contract = await getContract('erc20', abis.DiamondToNFT.contract)
				erc20Contract.methods
					.allowance(walletAddress, diamondToNFTContract._address)
					.call()
					.then(async allowance => {
						if (parseFloat(WEB3.utils.fromWei(`${WEB3.utils.toWei(`${nft.price * nft.quantity}`)}`)) > parseFloat(WEB3.utils.fromWei(`${allowance}`))) {
							approveFunc = await erc20Contract.methods.approve(nftBoxContract._address, MAX)
							approveFunc
								.send({ from: walletAddress, gas: gas })
								.then(() => {})
								.catch(err => {
									NotificationManager.error(err.message, 'ERROR', timeout, () => {})
									setLoading(false)
								})
						}
					})
					.catch(err => {
						NotificationManager.error(err.message, 'ERROR', timeout, () => {})
						setLoading(false)
					})
				const convert = await diamondToNFTContract.methods.convert(nft.packageId, WEB3.utils.toWei(`${nft.price * nft.quantity}`))
				convert
					.send({ from: walletAddress, gas: gas })
					.then(() => {
						NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
						setLoading(false)
						window.location.reload()
					})
					.catch(err => {
						NotificationManager.error(err.message, 'ERROR', timeout, () => {})
						setLoading(false)
					})
				break
			case 'PackageSale':
				setLoading(true)
				if (wallet.nfts) {
					const quantity = wallet.nfts[`${nft.name}`]
					if (quantity && quantity + nft.quantity > nft.max) {
						const max = nft.max - packages.quantity
						NotificationManager.error(`You can get a maximum of ${max}...`, 'ERROR', timeout, () => {})
					} else {
						callPackageSale(quantity + nft.quantity)
					}
				} else {
					callPackageSale(nft.quantity)
				}
				break
			case 'Sell':
				setLoading(true)
				NotificationManager.warning('Processing...', 'WARNING', timeout, () => {})
				marketContract = await getContract('Market')
				if (nft.collectionId === 0) {
					const oceanlandNftContract = await getContract('OceanLandNft')
					const isApprovedForAll = await oceanlandNftContract.methods
						.isApprovedForAll(walletAddress, marketContract._address)
						.call()
						.then(async res => {
							if (!res) {
								approveFunc = await oceanlandNftContract.methods.setApprovalForAll(oceanlandMarketAddress, true)
								approveFunc
									.send({ from: walletAddress, gas: gas })
									.then(() => {})
									.catch(err => {
										NotificationManager.error(err.message, 'ERROR', timeout, () => {})
										setLoading(false)
									})
							}
						})
						.catch(err => {
							NotificationManager.error(err.message, 'ERROR', timeout, () => {})
							setLoading(false)
						})
					const createAskOrder = await marketContract.methods.createAskOrder(oceanlandNFTContractAddress, [nft.nftTokenId], [1], WEB3.utils.toWei(`${price}`))
					createAskOrder
						.send({ from: walletAddress, gas: gas })
						.then(() => {
							NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
							setLoading(false)
							window.location.reload()
						})
						.catch(err => {
							NotificationManager.error(err.message, 'ERROR', timeout, () => {})
							setLoading(false)
						})
				} else {
					const nftBoxContract = await getContract('NFTBox')
					const isApprovedForAll = await nftBoxContract.methods
						.isApprovedForAll(walletAddress, marketContract._address)
						.call()
						.then(async res => {
							if (!res) {
								approveFunc = await nftBoxContract.methods.setApprovalForAll(oceanlandMarketAddress, true)
								approveFunc
									.send({ from: walletAddress, gas: gas })
									.then(() => {})
									.catch(err => {
										NotificationManager.error(err.message, 'ERROR', timeout, () => {})
										setLoading(false)
									})
							}
						})
						.catch(err => {
							NotificationManager.error(err.message, 'ERROR', timeout, () => {})
							setLoading(false)
						})
					const createAskOrder = await marketContract.methods.createAskOrder(oceanlandNFTBoxContractAddress, [nft.nftTokenId], [1], WEB3.utils.toWei(`${price}`))
					createAskOrder
						.send({ from: walletAddress, gas: gas })
						.then(() => {
							NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
							setLoading(false)
							window.location.reload()
						})
						.catch(err => {
							NotificationManager.error(err.message, 'ERROR', timeout, () => {})
							setLoading(false)
						})
				}
				break
			case 'Cancel':
				setLoading(true)
				NotificationManager.warning('Processing...', 'WARNING', timeout, () => {})
				marketContract = await getContract('Market')
				const cancelAskOrder = await marketContract.methods.cancelAskOrder(nft.collectionId === 0 ? oceanlandNFTContractAddress : oceanlandNFTBoxContractAddress, nft.packageId)
				cancelAskOrder
					.send({ from: walletAddress, gas: gas })
					.then(() => {
						NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
						setLoading(false)
						window.location.reload()
					})
					.catch(err => {
						NotificationManager.error(err.message, 'ERROR', timeout, () => {})
						setLoading(false)
					})
				break
			case 'OpenBox':
				setLoading(true)
				NotificationManager.warning('Processing...', 'WARNING', timeout, () => {})
				const nftBoxContract = await getContract('NFTBox')
				const openBox = await nftBoxContract.methods.openBox(nft.nftTokenId)
				openBox
					.send({ from: walletAddress, gas: gas })
					.then(async res => {
						NotificationManager.success('Completed...', 'SUCCESSFUL', timeout, () => {})
						setLoading(false)
						await getNFTPairs(res.events.BoxOpened.returnValues.nftIds, res.events.BoxOpened.returnValues.amounts)
					})
					.catch(err => {
						NotificationManager.error(err.message, 'ERROR', timeout, () => {})
						setLoading(false)
					})
				break
			default:
				setLoading(true)
				NotificationManager.error('Invalid transaction request please check!', 'ERROR', timeout, () => {})
				setLoading(false)
		}
	}

	return (
		<Fragment>
			<div className="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<div className="modal-body space-y-20 pd-40">
							<h3>{nft.name}</h3>
							<p className="text-center" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox' || type === 'Sell'}>
								Cheapest on the Market : <span className="price color-popup">{nft.price} OLAND</span>
							</p>
							<div className="d-flex justify-content-center" hidden={type === 'OpenBox'}>
								<img src={nft.image} alt="" loading="lazy" style={{ width: '217px', height: '291px' }} hidden={type === 'OpenBox'} />
							</div>
							<p className="text-center" hidden={type !== 'PackageSale'}>
								Total Price : <span className="price color-popup">{nft.price * nft.quantity} BUSD</span>
							</p>
							<p className="text-center" hidden={!nft.quantity && type !== 'PackageSale'}>
								Quantity : <span className="price color-popup">{nft.quantity}</span>
							</p>
							<input id="textPrice" type="number" className="form-control" placeholder="00.00 OLAND" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'} onChange={e => setPrice(e.target.value)} />
							<div className="hr"></div>
							<div className="d-flex justify-content-between" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>
								<p hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>Price : </p>
								<p className="text-right price color-popup" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>
									{price} OLAND
								</p>
							</div>
							<div className="d-flex justify-content-center" hidden={type === 'Sell' || type === 'OpenBox'}>
								<p className="text-right price color-popup" hidden={type === 'Sell' || type === 'OpenBox'}>
									Price : {nft.price} OLAND
								</p>
							</div>
							<div className="d-flex justify-content-center" hidden={type !== 'Cancel' || type === 'OpenBox'}>
								<p className="text-right price color-popup" hidden={type !== 'Cancel' || type === 'OpenBox'}>
									Are you sure you want to cancel ?
								</p>
							</div>
							<div className="d-flex justify-content-between" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>
								<p hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>Service fee : </p>
								<p className="text-right price color-popup" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>
									{formatter.format(price * 0.05)} OLAND
								</p>
							</div>
							<div className="d-flex justify-content-between" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>
								<p hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>You well receive : </p>
								<p className="text-right price color-popup" hidden={type === 'Buy' || type === 'BuyBooster' || type === 'PackageSale' || type === 'Cancel' || type === 'OpenBox'}>
									{price - price * 0.05} OLAND
								</p>
							</div>
							<div className="d-flex justify-content-center" hidden={type !== 'OpenBox'}>
								<img src="/assets/oceanland/nfts/question.png" alt="" loading="lazy" style={{ width: '217px', height: '291px' }} hidden={type !== 'OpenBox' || newNFTs.length > 0} />
								<div className="row" hidden={newNFTs.length === 0}>
									{newNFTs.map((newNFT, index) => {
										return (
											<div key={`item_${index}`} className="fl-item col-xl-6 col-lg-4 col-md-6 col-sm-6 d-block">
												<img src={`${newNFT.image}`} alt="" loading="lazy" />
											</div>
										)
									})}
								</div>
							</div>
							<LoadingButton color="primary" className="sc-button style w-100 wallet fl-button text-center pri-1 btn-primary" onClick={e => (type === 'OpenBox' && page === 'Inventory' && buttonLoading ? window.location.reload() : handleClick(e))} loading={loading} disabled={loading} block={true}>
								{type === 'OpenBox' && page === 'Inventory' && buttonLoading ? 'Close' : type === 'Cancel' ? 'Approve' : text}
							</LoadingButton>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
